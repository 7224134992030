import './App.css';

function App() {
  const url = new URL(window.location.href);

  return (
    <div className="App">
      <div className="App-content">
        <p>{ url.host }</p>
      </div>
    </div>
  );
}

export default App;
